import axios from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { dev_url, local_url, prod_url } from "../../apiurls";

export const fetchCustomersData = async (
  setIsLoading,
  setCustomers,
  setRoutes,
  setFilteredCustomers,
  customersDeleted,
  setUniqueDivisions,
  setUniqueChannels,
  setUniqueSubchannels
) => {
  setIsLoading(true);

  try {
    const { user_country } = jwtDecode(Cookies.get("Price_App_Token"));

    const response = await axios.get(
      // `${prod_url}/customers/${user_country}`,
      `${prod_url}/customers/${user_country}?customers_deleted=${customersDeleted}`,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    if (response.data && Array.isArray(response.data.data)) {
      let uniqueDivisions = new Set();
      let uniqueChannels = new Set();
      let uniqueSubchannels = new Set();
      let CustomersData = response.data.data.map((user, index) => {
        if (
          user.customer_division &&
          user.customer_division.toLowerCase() !== "nan"
        ) {
          uniqueDivisions.add(user.customer_division);
        }
        if (
          user.customer_channel &&
          user.customer_channel.toLowerCase() !== "nan"
        ) {
          uniqueChannels.add(user.customer_channel);
        }
        if (
          user.customer_subchannel &&
          user.customer_subchannel.toLowerCase() !== "nan"
        ) {
          uniqueSubchannels.add(user.customer_subchannel);
        }
        return {
          id: user.customer_id,
          ...user,
          rowNumber: index + 1,
        };
      });
      setUniqueDivisions(Array.from(uniqueDivisions));
      setUniqueChannels(Array.from(uniqueChannels));
      setUniqueSubchannels(Array.from(uniqueSubchannels));
      setFilteredCustomers(CustomersData);

      setCustomers(CustomersData);
    } else {
      setFilteredCustomers([]);
      setCustomers([]);
    }

    setRoutes(response.data.routes);
  } catch (error) {
    setFilteredCustomers([]);
    setCustomers([]);
    console.error("Error fetching Customers:", error);
  } finally {
    setIsLoading(false);
  }
};

/////////////////// Function to edit the route name or route app id
export const editStore = async (values, customer_id) => {
  try {
    const response = await axios.post(
      // `${prod_url}/customer_modify/${customer_id}`,
      `${prod_url}/customer_modify/${customer_id}`,

      values,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    const { message } = response.data;
    return { message };
  } catch (e) {
    if (!e.response) {
      // Network error or server is down
      return {
        message: "Network error . Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      // Server error
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      // Other errors
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  }
};

////// function to add Store////////////////////
export const AddStores = async (values) => {
  try {
    const response = await axios.post(
      // `${prod_url}/add_customer`,
      `${prod_url}/add_customer`,

      values,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    const { message } = response.data;
    return { message };
  } catch (e) {
    if (!e.response) {
      // Network error or server is down
      return {
        message: "Network error . Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      // Server error
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      // Other errors
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  }
};
/////////////////////// changing status for store
export const changeCustomerStatus = async (customer_id, data) => {
  try {
    const response = await axios.put(
      // `${prod_url}/customerStatus/${customer_id}`,
      `${prod_url}/customerStatus/${customer_id}`,

      data,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    const { message } = response.data;
    return { message };
  } catch (e) {
    if (!e.response) {
      // Network error or server is down
      return {
        message: "Network error . Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      // Server error
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      // Other errors
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  }
};
//////////////// Restoring Sales Rep
export const RestoreCustomer = async (customer_id) => {
  const data = {};
  try {
    const response = await axios.put(
      `${prod_url}/restorecustomer/${customer_id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    const { message } = response.data;
    return { message };
  } catch (e) {
    return {
      message: e.response ? e.response.data.message : "An error occurred",
    };
  }
};
///////////// **********************Deleting sales reps or an admin******************************************/////////////////////////
export const deleteCustomers = async (customer_id) => {
  try {
    const response = await axios.delete(
      `${prod_url}/customer_modify/${customer_id}`,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    const { message } = response.data;
    return { message };
  } catch (e) {
    return {
      message: e.response ? e.response.data.message : "An error occurred",
    };
  }
};
